import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "@igloonet-web/shared-ui/themes/igloonet"

import { Slider, Link, ContactFormJobs } from "@igloonet-web/shared-ui"

import {
  JobHeader,
  JobIntroduction,
  JobSection,
  JobWrapper,
} from "../components"

import ProfileKepi from "../images/team/profile/kepi.jpg"
import ProfileBara from "../images/team/profile/bara.jpg"
import ProfileKaterina from "../images/team/profile/katerina.jpg"

const JobDetailBody = styled.div`
  position: relative;
`

const JobDetailContent = styled.div`
  display: block;
  margin: auto;
  line-height: 1.5;
  max-width: 690px;
`

const RubyVyvojarKaJobDetail = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileBara,
      name: "Bára",
      position: "administrativa",
    },
    {
      id: 2,
      imageSrc: ProfileKepi,
      name: "Kepi",
      position: "Vedoucí hostingu & devops",
    },
    {
      id: 3,
      imageSrc: ProfileKaterina,
      name: "Kateřina",
      position: "projekťačka a front-enďák",
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <JobWrapper>
        <JobHeader />

        <JobDetailBody>
          <JobDetailContent className="px-2 px-lg-0">
            <JobIntroduction title="Ruby vývojář(ka) pro interní systémy">
              <p>
                <strong>
                  Ahoj, jsme <Link to="/">igloonet</Link>
                </strong>{" "}
                a už spoustu let děláme hosting, vývoj a marketing.
              </p>
              <p>
                <strong>TL;DR:</strong> Hledáme šikovného externího (či
                part-time) partáka, který by se nám staral o&nbsp;současné
                interní systémy v Ruby a řešil jejich další rozvoj. Někoho, kdo
                v&nbsp;Ruby rozumně umí a zvládne pracovat převážně sám.
              </p>
            </JobIntroduction>

            <JobSection title="Proč je tato pozice důležitá?">
              <ul>
                <li>
                  Vše u nás řešíme v Redmine a právě o ten se budeš primárně
                  starat.
                </li>
                <li>
                  Máme spoustu drobných nápadů na vylepšení, ale nikoho, kdo by
                  nám je zhmotnil.
                </li>
                <li>
                  Díky tobě budeme do práce i na home office chodit ještě
                  spokojeněji než doteď.
                </li>
              </ul>
            </JobSection>

            <JobSection title="Je práce pro tebe?">
              <p>
                <strong>Co budeš mít na starost</strong>
              </p>
              <ul>
                <li>Aktualizaci našeho Redmine a všech pluginů.</li>
                <li>Vývoj nových pluginů dle naší potřeby.</li>
                <li>Dokumentaci všech úprav.</li>
                <li>Rozvoj a vylepšování dalších interních systémů.</li>
              </ul>

              <p>
                <strong>Na čem nám záleží</strong>
              </p>
              <ul>
                <li>
                  <strong>Je na tebe spoleh. Bez výmluv.</strong>
                </li>
                <li>
                  Rozumíš si s Ruby i Railsama. Na začátek nám jde primárně o
                  Redmine a jeho pluginy, tzn. žádné raketové inženýrství. Není
                  třeba být Ruby expert s&nbsp;dekádou zkušeností.
                </li>
                <li>Zvládneš se vyznat v existujícím kódu.</li>
                <li>
                  Najdeš si potřebné věci v dokumentaci, umíš se zorientovat v
                  potřebných API a využívat je.
                </li>
                <li>Rozumíš si s Gitem.</li>
              </ul>
              <p>
                <strong>Na čem nám naopak nezáleží</strong>
              </p>
              <ul>
                <li>
                  Kolik je ti zim, jestli čůráš ve stoje nebo v sedě, jak se
                  oblékáš, s kým chceš žít nebo kdo jsou tví rodiče.
                </li>
                <li>
                  Na tvém vzdělání. Fakt ne. Ani na maturitě, ani na titulech,
                  ani na certifikátech čí diplomech (s výjimkou <i>BDP/BSc</i>
                  ).
                </li>
                <li>Na nedostatku formální praxe či zářivých referencích.</li>
              </ul>
              <p>
                <strong>Naše sny</strong>
              </p>
              <ul>
                <li>
                  Máš dobrý vztah k Free software. Když najdeš bug, tak jej i
                  nahlásíš a pomůžeš s&nbsp;řešením.
                </li>
                <li>Kromě Ruby zvládáš i Python.</li>
                <li>
                  Vyznáš se v Redmine a už 30 let pro něj píšeš pluginy ;)
                </li>
              </ul>
            </JobSection>

            <JobSection title="Co tě u nás čeká?">
              <p>
                Na celkovém rozsahu se domluvíme podle toho, jak ti to bude
                vyhovovat. Čistě Ruby věci určitě nejsou na plný úvazek,
                respektive ne dlouhodobě. Pro představu - náš odhad je cca
                40&nbsp;hod / měsíc, ale neptej se, odkud jsme jej vytáhli. Může
                to být o dost fousů míň i víc.
              </p>
              <p>
                Počítáme spíš s externí spoluprácí, ale místa v kanclech máme
                případně dost. Pokud budeš chtít a osvědčíš se, můžeme
                spolupráci časem rozšířit.
              </p>
              <p>
                Na začátek by tě čekal Redmine, respektive jeho upgrade. Máme
                vše docela dobře zdokumentováno, i tak to ale bude asi dost
                práce. Jakmile se povede, projdeme si priority a vrhneme se na
                to, co se nám kupí v backlogu.
              </p>
              <p>
                Možná to nejdůležitější nakonec - ten rozsah je opravdu na
                domluvě. Samozřejmě bychom rádi měli všechno hned, na druhou
                stranu ale nic fakticky nehoří, takže tempo bude hodně na tom,
                jak ti to bude vyhovovat.
              </p>
            </JobSection>

            <Slider
              title={"S kým budeš pracovat?"}
              data={personSlider}
              style={{ minHeight: "28rem" }}
            />
          </JobDetailContent>

          <ContactFormJobs heading="Napiš Kepimu a zbytečně to neodkládej" />
        </JobDetailBody>
      </JobWrapper>
    </ThemeProvider>
  )
}

export default RubyVyvojarKaJobDetail
